* {
  padding: 0;
  margin: 0;
  outline: none;
  list-style: none;
  font-family: inherit;
  color: inherit;
  box-sizing: border-box;
 }

 a {
  text-decoration: none;
 }

 html,
 body,
 #root {
  min-height: 100%;
  height: 1px;
  background-color: #F3F3F3;
 }

 #root {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
 }

 .cursor-pointer {
  cursor: pointer;
 } 
 
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-layout-container {
  height: 100vh;
  width: 100vw;

  background-color: #EAEEF0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-btn-primary.ant-btn-primary {
  box-shadow: 0px 2px 0px 0px #0000000B
}

.ant-dropdown-menu.ant-dropdown-menu {
  max-height: 280px;
  overflow-y: auto;
}

.ant-picker-dropdown {
  position: absolute !important;
  inset: 0 !important;
  top: calc(50% - 175px) !important;
  left: calc(50% - 80px) !important;
}

body.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
.clients-content_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clients-content_container:hover {
  cursor: pointer;
}

.logout-content_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.clients-layout-container {
  height:max-content;

  flex: 1;

  background-color: #F3F3F3;

  display: flex;
  flex-direction: column;
  gap: 30px;
}


.add-edit-form {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
  display: flex;
}

.formContainer {
  width: 570px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  gap: 18px;

  margin: auto;
  padding: 0px 16px 16px 16px;

  border-radius: 2px;
  background-color: #ffffff;
  
}

.closeIconContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px 0 0 0;
}

.closeIcon.closeIcon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border-width: 0;
  background: #EBEBEB;
  display: flex;
  justify-content: center;
}

.addEditFormTitle.addEditFormTitle {
  font-weight: 600;
  font-size: 24px;
  text-align: center;

  margin: 0;
  padding: 16px 0;
}

.inputContainer {
  width: 100%;
  height: 40px;
  padding: 0 70px;
  margin: 0;
}

.inputName {
  border-radius: 2px;
}

.dateInput {
  border-radius: 2px;

  width: 100%;
  height: 40px;
}

.buttonContainer {
  width: 100%;
  height: 40px;
  padding: 0 70px;
  margin-bottom: 62px;
}

.addButton {
  width: 100%;
  border-radius: 2px;
  margin-bottom: 30px;

  box-shadow: 0px 2px 0px 0px #0000000B
}

.folderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  background-color: #f64545;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 6px;
}
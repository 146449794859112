.loginForm {
  width: 330px;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
}

.loginTitle.loginTitle {
  margin: 28px 0px;
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoText.logoText {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  color: #1A546D;
  margin-bottom: 50px;
}

.passwordWrapper {
  width: 100%;
  height: 40px;
  padding: 0 32px;
}

.passwordInput {
  border-radius: 2px;
}

.loginButtonWrapper {
  width: 100%;
  height: 40px;
  padding: 0 32px;
}

.submitButton {
  width: 100%;
  border-radius: 2px;

  box-shadow: 0px 2px 0px 0px #0000000B;
}
.client-list-container {
  flex: 1;
  background-color: #FFFFFF;
  box-sizing: border-box;
  min-width: 1000px;
  min-height: 650px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 40px 70px 20px 70px;
  margin: 0 70px;
}

.client-list_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-card_name {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  max-width: 50%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;

  gap: 8px;
}

.client-card_content_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client-registration-text {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.client-card {
  width: 100%;
  height: 85px;

  background-color: #F3F3F3;

  padding: 0px 20px;
  box-sizing: border-box;

  display: grid;
  place-items: center;  
}

.pointText.pointText {
  font-weight: 500;
  font-size: 20px;
  margin: 0;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.clientPoints.clientPoints.clientPoints {
  font-weight: 300;
  font-size: 18px;
  margin: 0 !important;
  color: #1A546D;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.dateContainer.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.registrationDate.registrationDate {
  font-weight: 300;
  font-size: 14px;
  margin: 0 !important;
  margin-right: 10px;
}
.editDate.editDate {
  font-weight: 400;
  font-size: 12px;
  color: #1A546D;
  margin: 0 !important;
  margin-right: 10px;
}

.dividerContainer {
  height: 20px;
}

.clientsTitle.clientsTitle {
  margin-left: 10px;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  height: 40px;

  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.clientsTitleNumber {
  color: #1A546D;
}

.addClientButton {
  width: 180px;
  border-radius: 2px;

  box-shadow: 0px 2px 0px 0px #0000000B
}

.newCodeButton {
  width: 180px;
  border-radius: 2px;
}


.pagination {
  align-self: center;
}

.closeIcon.closeIcon {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.code.code {
  margin: 0;
  padding: 12px;
  background-color: #FFFFFF;
}

.codeContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.clientTitleContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.client-list_count {
  display: inline-flex;
  gap: 4px;
  align-items: center;
  margin: 4px 0px;
}

.client-list_count-text.client-list_count-text {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  color: #000000;
}

.goBackButton.goBackButton {
  width: 32px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0;
}

.searchBar {
  margin-bottom: 16px;
  border-radius: 2px;
}

.folderContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  background-color: #f64545;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 6px;
}

.pointNameContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.clientId {
  font-weight: 400;
  font-size: 14px;
  color: #626a72;
}
.client-list-container {
  flex: 1;
  background-color: #FFFFFF;
  box-sizing: border-box;
  min-width: 900px;
  min-height: 650px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  padding: 40px 70px 20px 70px;
  margin: 0 70px;
}

.client-list_controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.client-card_name {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 50%;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;

  gap: 8px;
}

.client-card_content_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action_item {
  position: absolute;
  width: 80%;
  height: 80px;
}

.action_item:hover {
  cursor: pointer;
}

.client-registration-text {
  display: flex;
  align-items: center;
  justify-content: center; 
}

.client-card {
  position: relative;
  width: 100%;
  height: 85px;

  background-color: #F3F3F3;

  padding: 0px 20px;
  box-sizing: border-box;

  display: grid;
}

.companyText.companyText {
  font-weight: 500;
  font-size: 20px;
  margin: 0;


  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.clientPoints.clientPoints {
  font-weight: 300;
  font-size: 14px;
  margin: 0;
}

.registrationDate.registrationDate {
  font-weight: 300;
  font-size: 14px;
  margin: 0 !important;
  margin-right: 10px;
}

.dividerContainer {
  height: 20px;
}

.clientsTitle {
  margin-left: 10px;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
}

.clientsTitleNumber {
  color: #1A546D;
}

.addClientButton {
  width: 180px;
  border-radius: 2px;

  box-shadow: 0px 2px 0px 0px #0000000B
}

.pagination {
  align-self: center;
}

.searchBar {
  margin-bottom: 16px;
  border-radius: 2px;
}
